<template>
    <frontoffice-layout :title="$t('my_bookings')">
        <section class="shell">
            <v-card>
                <v-table :rows="bookings" :columns="columns" @on-cell-click="props => $redirect('member-booking-detail', {id: props.row.id})">
                    <template #table-row="props">
                        <template v-if="props.column.field == 'itinerary'">
                            <div class="flex items-center">
                                <span class="block bg-blue-gray-100 rounded-full w-8 h-8 basis-8 flex-grow-0 flex-shrink-0 mr-4 flex items-center justify-center">
                                    <v-icon name="arrow-down" size="sm" />
                                </span>

                                <div class="flex flex-col">
                                    <p class="mb-1"> {{ props.row.pickup_address }} </p>

                                    <p> {{ props.row.dropoff_address }} </p>
                                </div>
                            </div>
                        </template>
                    </template>
                </v-table>
            </v-card>
        </section>
    </frontoffice-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    data: () => ({
        columns: [{
            label: 'booking_id',
            field: 'token',
            width: '140px'
        }, {
            label: 'itinerary',
            field: 'itinerary',
            width: '280px'
        }, {
            label: 'date_time',
            field: 'schedule_time',
            type: 'date',
            width: '260px'
        }, {
            label: 'price',
            field: 'price',
            type: 'price',
            width: '80px'
        }, {
            label: 'status',
            field: 'status',
            type: 'tag',
            width: '100px'
        }],
    }),

    computed: {
        ...mapGetters('member_bookings', [
            'bookings',
        ]),
    },

    mounted () {
        this.getBookings();
    },

    methods: {
		...mapActions('member_bookings', [
            'getBookings',
		])
    }
}
</script>
